import { useState, useEffect } from "react"
import Swipe from "react-swipeable-views"
import Image from "gatsby-image"
import styled from "styled-components"

import { COLORS, LAYOUT } from "src/layout/constants"
import LeftIcon from "src/svg/left.svg"
import RightIcon from "src/svg/right.svg"

const Container = styled.section`
  position: relative;
`
const Slide = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: calc(100vh - 112px);
    max-height: 600px;
    @media (orientation: portrait) {
      max-height: 100vw;
    }
  }
`
const Text = styled.div`
  position: absolute;
  top: 10%;
  left: 2%;
  max-width: 500px;
  h1 {
    display: inline;
    font-size: 2.75em;
    line-height: 1.5em;
    span {
      box-shadow: ${COLORS.translucentSherpa} 10px 0px 0px 0px, ${COLORS.translucentSherpa} -10px 0px 0px 0px;
      background-color: ${COLORS.translucentSherpa};
    }
  }
  @media (max-width: 799px) {
    top: ${LAYOUT.margin}px;
    left: ${LAYOUT.margin}px;
    right: ${LAYOUT.margin}px;
    h1 {
      font-size: 2em;
      line-height: 1.3em;
    }
  }
`
const Controls = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  button {
    margin: ${LAYOUT.margin}px 10px;
    svg circle {
      fill: ${COLORS.white};
    }
  }
`

interface Props {
  slides: HomePhoto[]
}

const Carousel = ({ slides }: Props) => {
  const [index, setIndex] = useState(0)
  const [clicked, setClicked] = useState(false)

  const handleManual = (idx: number) => {
    setClicked(true)
    setIndex(idx)
  }

  const handlePrevious = () => {
    handleManual(index === 0 ? slides.length - 1 : index - 1)
  }

  const handleNext = () => {
    handleManual(index === slides.length - 1 ? 0 : index + 1)
  }

  useEffect(() => {
    if (clicked) {
      return
    }

    const next = () => {
      setIndex(index < slides.length - 1 ? index + 1 : 0)
    }

    if (process.env.NODE_ENV === "development") {
      // no auto sliding in dev, it's just annoying me
      return
    }

    const timeout = setTimeout(next, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [slides.length, clicked, index])

  return (
    <Container>
      <Swipe index={index} onChangeIndex={handleManual} resistance>
        {slides.map(({ desktopPhoto, mobilePhoto, text, id }, i) => (
          <Slide key={id}>
            <Image
              fluid={[desktopPhoto.fluid, { ...mobilePhoto.fluid, media: "(orientation: portrait)" }]}
              loading={i <= index + 1 ? "eager" : "lazy"}
            />
            <Text>
              <h1>
                <span>{text}</span>
              </h1>
            </Text>
          </Slide>
        ))}
      </Swipe>
      <Controls>
        <button onClick={handlePrevious} aria-label="Diapositive précédente">
          <LeftIcon />
        </button>
        <button onClick={handleNext} aria-label="Diapositive suivante">
          <RightIcon />
        </button>
      </Controls>
    </Container>
  )
}

export default Carousel
