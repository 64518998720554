import { useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

import Layout from "src/layout"
import { Column } from "src/layout/styled"
import { COLORS, LAYOUT, FONTS } from "src/layout/constants"
import Carousel from "src/components/Carousel"
import Missions from "src/components/Missions"
import MissionBox from "src/components/Mission"
import EventBox from "src/components/Event"
import Agenda from "src/components/Agenda"
import { getDateLabel } from "src/helpers/date"
import { ICONS } from "src/templates/activity"

import RightIcon from "src/svg/right.svg"
import MegaphoneIcon from "src/svg/megaphone.svg"
import AgendaIcon from "src/svg/agenda.svg"
import PlusIcon from "src/svg/plus.svg"

const FEATURED_PARTNERS = 12

const Top = styled(Column)`
  margin: 3em auto;
  h1 {
    font-size: 3.5em;
    color: ${COLORS.blue};
  }
  p {
    font-size: 2em;
    color: ${COLORS.lightBlue};
  }
  @media (max-width: 799px) {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1.3em;
    }
  }
`

const ActivitiesTitle = styled.h2`
  font-weight: normal;
  color: ${COLORS.pink};
  text-align: center;
`
const Activities = styled.div`
  max-width: 650px;
  display: flex;
  flex-wrap: wrap;
  margin: 48px auto;
  a {
    flex: 0 0 calc(33.33% - 16px);
    text-align: center;
    font-family: ${FONTS.title};
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid ${COLORS.white};
    margin: 8px;
    padding: 26px;
    white-space: pre-line;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    &:not(:last-of-type) {
      svg {
        width: 100px;
        height: 100px;
        margin: ${LAYOUT.margin}px;
        g {
          fill: ${COLORS.white};
        }
      }
    }
    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      position: relative;
      svg {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        circle {
          fill: rgba(255, 255, 255, 0.1);
        }
        polyline {
          stroke: ${COLORS.white};
        }
      }
    }
  }
  @media (max-width: 799px) {
    margin: 30px -5px;
    a {
      flex: 0 0 calc(50% - 10px);
      margin: 5px;
      padding: 10px 10px 15px;
      font-size: 1.4em;
      &:not(:last-of-type) svg {
        width: 60%;
        margin: 20px 10px;
        height: 80px;
      }
    }
  }
  @media (max-width: 349px) {
    a {
      font-size: 1.2em;
      padding: 10px 0 15px;
    }
  }
`

const MissionsSection = styled.section`
  background-color: ${COLORS.white};
  padding: ${LAYOUT.margin * 4}px 0;
  @media (max-width: 799px) {
    padding: 25px 0 55px;
  }
`
const Center = styled.div`
  text-align: center;
  svg {
    width: 142px;
  }
  @media (max-width: 799px) {
    svg {
      width: 110px;
    }
  }
`
const Subtitle = styled.h2`
  color: ${COLORS.pink};
  font-size: 2em;
  text-align: center;
  font-family: ${FONTS.title};
`

const AgendaSection = styled.section`
  background-color: ${COLORS.lightBlue};
  padding: ${LAYOUT.margin * 4}px 0;
  position: relative;
  @media (max-width: 799px) {
    padding: ${LAYOUT.margin}px 0;
    ${Column} {
      padding-top: 10px;
    }
  }
`

const PartnersSection = styled.div`
  background-color: ${COLORS.white};
  padding: ${LAYOUT.margin}px 0;
`
const PartnersTitle = styled.h2`
  color: ${COLORS.sherpa};
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 2em;
  @media (max-width: 799px) {
    font-size: 1em;
  }
`
const PartnersMosaic = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${LAYOUT.margin}px 0;
  margin: -20px;
  .gatsby-image-wrapper {
    margin: 20px;
  }
  @media (max-width: 799px) {
    font-size: 1em;
    margin: -15px;
    .gatsby-image-wrapper {
      margin: 15px;
      width: 90px !important;
      height: 75px !important;
    }
  }
`
const Toggler = styled.button`
  display: block;
  text-align: right;
  margin-left: auto;
  border-top: 2px solid ${COLORS.blue};
  padding: 12px 0 0 40px;
  @media (max-width: 799px) {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`

interface Data {
  slides: { nodes: HomePhoto[] }
  activities: { nodes: Activity[] }
  missions: { nodes: Mission[] }
  events: { nodes: AgendaEvent[] }
  partners: { nodes: Partner[] }
}

const HomePage: GatsbyPage<Data> = ({ data }) => {
  const [allPartners, setAll] = useState(false)

  return (
    <Layout theme="dark">
      <Carousel slides={data.slides.nodes} />
      <Top>
        <h1>
          Agence d’études,
          <br />
          de recherche et de conseil.
        </h1>
        <p>
          Spécialistes de l’évaluation de politiques publiques et d’impact social, nous mobilisons la force des sciences
          sociales pour innover, aider au dialogue et accompagner la prise de décision.
        </p>
      </Top>
      <Column>
        <ActivitiesTitle>NOS MISSIONS</ActivitiesTitle>
        <Activities>
          {data.activities.nodes.map(({ slug, icon, title }) => {
            const Icon = ICONS[icon]
            return (
              <Link to={`/activites/${slug}/`} key={slug}>
                <Icon />
                <br />
                {title.split(" ").length > 3 ? title : title.replace(" et ", "\net\n")}
              </Link>
            )
          })}
          <Link to="/missions/">
            Voir toutes nos missions
            <RightIcon />
          </Link>
        </Activities>
      </Column>
      <MissionsSection>
        <Center>
          <MegaphoneIcon />
          <Subtitle>missions en cours</Subtitle>
        </Center>
        <Missions>
          {data.missions.nodes.map((mission, index) => (
            <MissionBox key={mission.id} mission={mission} light={index % 2 !== 0} />
          ))}
        </Missions>
      </MissionsSection>
      <AgendaSection>
        <Center>
          <AgendaIcon />
          <Subtitle>agenda</Subtitle>
        </Center>
        <Column>
          <Agenda>
            {data.events.nodes.map((event) => (
              <EventBox key={event.id} event={event} type="recent" label={getDateLabel(event)} />
            ))}
          </Agenda>
        </Column>
      </AgendaSection>
      <PartnersSection>
        <Column>
          <PartnersTitle>Ils nous font confiance :</PartnersTitle>
          <PartnersMosaic>
            {data.partners.nodes.slice(0, FEATURED_PARTNERS).map((partner) => (
              <Image key={partner.id} fixed={partner.logo.fixed} alt={partner.name} />
            ))}
          </PartnersMosaic>
          {allPartners ? (
            <PartnersMosaic>
              {data.partners.nodes.slice(FEATURED_PARTNERS).map((partner) => (
                <Image key={partner.id} fixed={partner.logo.fixed} alt={partner.name} />
              ))}
            </PartnersMosaic>
          ) : (
            <Toggler onClick={() => setAll(true)} title="Voir tous les partenaires">
              <PlusIcon />
            </Toggler>
          )}
        </Column>
      </PartnersSection>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    slides: allContentfulHomeSlide(sort: { fields: position, order: ASC }) {
      nodes {
        id: contentful_id
        text
        desktopPhoto {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        mobilePhoto {
          fluid(maxWidth: 500, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    activities: allContentfulActivity(sort: { fields: position }) {
      nodes {
        id: contentful_id
        slug
        icon
        title
      }
    }
    missions: allContentfulMission(filter: { featured: { eq: true } }, sort: { fields: end, order: DESC }) {
      nodes {
        id: contentful_id
        title
        subtitle
        client
        clientShort
        photo {
          fixed(width: 280, height: 280, quality: 75) {
            ...GatsbyContentfulFixed_noBase64
          }
        }
        fields {
          slug
        }
      }
    }
    events: allContentfulEvent(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        id: contentful_id
        date
        tags
        text {
          json
        }
      }
    }
    partners: allContentfulPartner(sort: { fields: position, order: ASC }) {
      nodes {
        id: contentful_id
        name
        logo {
          fixed(width: 180, height: 150, quality: 75, resizingBehavior: PAD) {
            ...GatsbyContentfulFixed_noBase64
          }
        }
      }
    }
  }
`
