import { useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { LAYOUT } from "src/layout/constants"
import { Grid, horizontalScroll } from "src/layout/styled"
import { scrollHorizontally } from "src/helpers/scroll"
import LeftIcon from "src/svg/left.svg"
import RightIcon from "src/svg/right.svg"
import PlusIcon from "src/svg/plus.svg"

const NUM_EVENTS_DESKTOP = 3

const Content = styled(Grid)`
  @media (max-width: 799px) {
    margin: 0 -${LAYOUT.margin}px;
    ${horizontalScroll}
    display: block;
    padding: 0 20px;
    > div {
      width: calc(100vw - ${LAYOUT.margin * 8}px);
      margin: 0 ${LAYOUT.margin * 3}px;
      display: inline-flex;
    }
  }
  @media (min-width: 800px) {
    > div:nth-of-type(n + ${NUM_EVENTS_DESKTOP + 1}) {
      display: none;
    }
  }
`
const Button = styled.button<{ $position: "left" | "right" }>`
  position: absolute;
  top: calc(50% - 20px);
  ${({ $position }) => $position}: 4px;
  svg {
    width: 40px;
    height: 40px;
    circle {
      fill: none;
    }
  }
  @media (min-width: 800px) {
    display: none;
  }
`
const More = styled.div`
  margin-top: -30px;
  text-align: right;
  @media (max-width: 799px) {
    display: none;
  }
`

const Agenda: React.FC = ({ children }) => {
  const scrollbars = useRef<HTMLDivElement>()

  const scroll = (direction: "left" | "right") => () => {
    const width = document.body.clientWidth - 2 * LAYOUT.margin
    const left = scrollbars.current.scrollLeft + width * (direction === "left" ? -1 : 1)

    scrollHorizontally(scrollbars.current, left)
  }

  return (
    <>
      <Button $position="left" onClick={scroll("left")} aria-label="Faire défiler vers le début">
        <LeftIcon />
      </Button>
      <Content ref={scrollbars}>{children}</Content>
      <Button $position="right" onClick={scroll("right")} aria-label="Faire défiler vers la fin">
        <RightIcon />
      </Button>
      <More>
        <Link to="/agenda/" title="Accéder à l'agenda complet">
          <PlusIcon />
        </Link>
      </More>
    </>
  )
}

export default Agenda
