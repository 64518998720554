import { useRef } from "react"
import styled from "styled-components"

import { COLORS, LAYOUT } from "src/layout/constants"
import { horizontalScroll } from "src/layout/styled"
import { scrollHorizontally } from "src/helpers/scroll"
import { WIDTH } from "src/components/Mission"
import LeftIcon from "src/svg/left.svg"
import RightIcon from "src/svg/right.svg"

const OUTER_ITEM_WIDTH = WIDTH + 2 * LAYOUT.margin

const Content = styled.div`
  padding: 30px ${LAYOUT.margin}px 0;
  ${horizontalScroll}
  text-align: center;
  > div {
    text-align: left;
    display: inline-block;
    margin: ${LAYOUT.margin}px;
  }
  @media (max-width: 799px) {
    padding-top: 0;
  }
`
const Controls = styled.div`
  max-width: ${LAYOUT.column}px;
  margin: 0 auto;
  padding: 5px ${LAYOUT.margin}px;
  text-align: right;
  button {
    padding: 0;
    margin-left: ${LAYOUT.margin}px;
    svg circle {
      fill: ${COLORS.sherpa};
    }
  }
`

const Missions: React.FC = ({ children }) => {
  const scrollbars = useRef<HTMLDivElement>()

  const scroll = (direction: "left" | "right") => () => {
    const num = Math.floor(document.body.clientWidth / OUTER_ITEM_WIDTH)
    const left = scrollbars.current.scrollLeft + num * OUTER_ITEM_WIDTH * (direction === "left" ? -1 : 1)

    scrollHorizontally(scrollbars.current, left)
  }

  return (
    <>
      <Content ref={scrollbars}>{children}</Content>
      <Controls>
        <button onClick={scroll("left")} aria-label="Faire défiler vers le début">
          <LeftIcon />
        </button>
        <button onClick={scroll("right")} aria-label="Faire défiler vers la fin">
          <RightIcon />
        </button>
      </Controls>
    </>
  )
}

export default Missions
