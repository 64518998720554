export const scrollHorizontally = (el: HTMLElement, left: number) => {
  if (typeof el.scroll === "function") {
    try {
      el.scroll({ left, behavior: "smooth" })
    } catch (e) {
      el.scrollTo(left, 0)
    }
  } else {
    // older browsers
    el.scrollLeft = left
  }
}
